import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import URL from "../Network/endpoint";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import { Paper } from "@mui/material";

import { LoadingContext } from "../context/loadingProviderContext";
import { getPurchaserApproverQuoteList, getViewersQuoteList } from "../ApisActions/quotesApisActions";
import { useState } from "react";
import moment from "moment";

const QuotesDataTable = () => {
  const { user } = useContext(AuthContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [quotesList, setQuotesList] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      borderBottom: "1px solid #EFEFEF",
      color: "#000",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "1px solid #EFEFEF",
      color: "#000",
      padding: "30px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      border: "1px solid #EFEFEF",
    },
  }));

  useEffect(() => {
    if (user?.user_role == "Viewer") {
      fetchViewersData();
    } else {
      fetchApproverPurchaserQuotesData();
    }
  }, [user]);

  const fetchApproverPurchaserQuotesData = async () => {
    const { data } = await getPurchaserApproverQuoteList(
      setLoading,
      user?.email
    );
    setQuotesList(data);
  };

  const fetchViewersData = async () => {
    const { data } = await getViewersQuoteList(setLoading, user?.email);
    setQuotesList(data);

    console.log("quotesList", quotesList);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "#fff", color: "#000" }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right">DATE</StyledTableCell>
            <StyledTableCell align="right">
              EXPIRATION DATE&nbsp;
            </StyledTableCell>
            <StyledTableCell align="right">TOTAL&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log("quotesList", quotesList)}
          {quotesList?.data?.map((element, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>#{element.id}</StyledTableCell>
                <StyledTableCell>
                  {element.first_name + " " + element.last_name} <br />
                  {element.email}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {moment(element.createdAt).format("MMM DD yyyy")}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {moment(element.expiration_date).format("MMM DD yyyy")}
                </StyledTableCell>
                <StyledTableCell align="right">
                  $ {element.total_price}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default QuotesDataTable;
