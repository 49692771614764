import { Box, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import { useLocation } from "react-router-dom";
import { useState, useContext } from "react";
import DatePickerModal from "../components/datePickerModal";
import "../assets/css/datepickercustom.css";
import { AuthContext } from "../context/authContext";

const getDefaultStartDate = () => {
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);
  return oneMonthAgo;
};

const TopBar = () => {
  const location = useLocation();
  const hideTopBar = location.state && location.state.hideTopBar;
  const { user } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [localDateRange, setLocalDateRange] = useState([
    {
      startDate: getDefaultStartDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // const formatDate = (date) => {
  //   return date.toLocaleDateString("en-US", {
  //     month: "short",
  //     day: "numeric",
  //     year: "numeric",
  //   });
  // };

  const formatDate = (date) => {
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <Box component={"div"} className="custom-topbar">
      <Box>
        <Typography
          fontSize={14}
          fontWeight={700}
          color={themeColors.textHeading}
        >
          Hi {user ? user?.first_name + "!" : ""}
        </Typography>
        <Typography variant="h6" color={themeColors.gray}>
          Here is your government dashboard.
        </Typography>
      </Box>

      {/* <Box display="flex">
        <button
          onClick={() => setOpen(!open)}
          style={{
            border: "none",
            backgroundColor: "transparent",
            padding: "0px",
          }}
        >
          <Typography
            variant="h6"
            color={themeColors.textHeading}
            className="time-and-date"
          >
            {localDateRange
              ? `${formatDate(localDateRange[0].startDate)} - ${formatDate(
                  localDateRange[0].endDate
                )}`
              : ""}
          </Typography>
        </button>

        <DatePickerModal
          open={open}
          setOpen={setOpen}
          localDateRange={localDateRange}
          setLocalDateRange={setLocalDateRange}
        />
      </Box> */}

      <Box component={"div"}>
        <Typography
          variant="h5"
          color={themeColors.gray}
          sx={{ marginBottom: "5px" }}
        >
          <b>Role:</b> {user?.user_role}
        </Typography>
        <Typography variant="h5" color={themeColors.gray}>
          <b>Division:</b>  {user?.division && JSON?.parse(user?.division)[0]}
        </Typography>
      </Box>
    </Box>
  );
};

export default TopBar;
