export const  baseUlr = "https://santamonica.projectanddemoserver.com/";

const URL = {
  USER_PROFILE: baseUlr + "users/customer-profile",
  ALL_USER: baseUlr + "users/list",
  ORDER_LIST: baseUlr + "orders/list",
  TRANSFER_LIST: baseUlr + "transfer-cart/list",
  APPROVED_API: baseUlr + "transfer-cart/approve",
  DECLINE_API: baseUlr + "transfer-cart/decline",
  APPROVED_DECLINED_LIST: baseUlr + "transfer-cart/approve-declined-list",
  MEMBERS_LIST: baseUlr + "users/readListByDivisionMembers",
  ADD_BUDGET: baseUlr + "users/order-budget",
  CHANGE_PURCHASE_STATUS: baseUlr + "users/purchase-status",
  CHANGE_USER_STATE: baseUlr + "users/status",
  ADD_MEMBER: baseUlr + "users/create-cardholder-user",
  EXPORT_DATA: baseUlr + "download-csv",
  SETTING_API: baseUlr + "users/settings",
  CREATESHOPIFYCHECKOUT_API: baseUlr + "transfer-cart/create-checkout",
  VIEWER_WORKFLOW_API: baseUlr + "transfer-cart/viewers-request-list",
  TRANSFERCART_ITEM: baseUlr + "transfer-cart/profile",
  TRANSFERCART_USER_OWN_REQUEST : baseUlr + "/transfer-cart/viewers-request-list",
  SEND_TO_APPROVER : baseUlr + "transfer-cart/update_transfer_cart_approve",
  QUOTES_PURCHASER_APPROVER: baseUlr + "quotes/approver-purchaser-list",
  QUOTES_VIEWERS: baseUlr + "quotes/viewers-list",
  PURCHASER_ANALYTICS: baseUlr + "purchasers/analytics"
};
export default URL;
