export const themeColors = {
  primary: "#17171A",
  tabColor: "#232a2e",
  gray: "#6d7278",
  backgroundColor: "#f2f3f5",
  secondary: "#626971",
  textHeading: "#0c151b",
  highLight: "#d7f62f",
  white: "#fff",
  pending: "#D9D9D9",
  onTrackColor: "#80C8FF",
  failureColor: "#FF4F4B",
  approvedColor: "#29C01C",
  palette: {
    action: {
      hover: "#000",
    },
    
  },
};
