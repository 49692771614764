import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Chip, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import "../assets/css/dashboard.css";
import "../assets/css/purchase.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DataTable = ({ orderListData }) => {
  const columns = [
    { id: "orderId", label: "Order ID" },
    { id: "status", label: "Status" },
    { id: "customer", label: "Customer" },
    { id: "date", label: "Date" },
    { id: "total", label: "Total" },
    { id: "actions", label: "Actions" },
  ];

  const classes = useStyles();

  const getModifiedStatus = (status) => {
    switch (status) {
      case "authorized":
        return "Authorized";
      case "pending":
        return "Pending";
      case "paid":
        return "Paid";
      case "partially_paid":
        return "Partially Paid";
      case "refunded":
        return "Refunded";
      case "voided":
        return "Voided";
      case "partially_refunded":
        return "Partially Refunded";
      case "any":
        return "Any";
      case "unpaid":
        return "Unpaid";
      default:
        return "Unknown Status";
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ background: "#ffffff", color: "#000" }}
      className="main-table-container"
    >
      <Box component={"div"} className="table-heading">
        <Box>
          <Typography variant="h6" color={themeColors.gray}>
            My Purchases
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" fontSize={12} fontWeight={400}>
            Filter By:
          </Typography>
        </Box>
      </Box>
      <Box className="purchase-table">
        <Table className={classes.table} aria-label="simple table">
          <TableHead sx={{borderBottom:"1px solid #D9D9D9", borderTop:"1px solid #D9D9D9"}}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{
                    color: "#6D7278",
                    borderBottom: "none",
                    fontSize:"18px",
                    fontWeight:400
                  }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderListData?.data?.map((element) => (
              <TableRow
                sx={{
                  "*": {
                    color: "#000000",
                  },
                }}
                key={element.id}
              >
                <TableCell
                  sx={{ color: "#000", borderBottom: "none", fontSize: "16px" }}
                  component="th"
                  scope="row"
                >
                  {element.order_id}
                </TableCell>
                <TableCell sx={{ color: "#000", borderBottom: "none" }}>
                  <Chip
                    label={JSON.parse(element.fulfillments)[0]?.status}
                    sx={{
                      width: "100px",
                      height: "25px",
                      borderRadius: "12px",
                      backgroundColor: (() => {
                        const status = JSON.parse(
                          element.fulfillments
                        )[0]?.status?.toLowerCase();
                        console.log("Status:", status);
                        if (status === "delivered") {
                          return themeColors.highLight;
                        } else if (status === "on track") {
                          return themeColors.onTrackColor;
                        } else if (status === "pending") {
                          return themeColors.pending;
                        } else if (status === "failure") {
                          return themeColors.failureColor;
                        } else {
                          console.log("Using default color");
                          return themeColors.defaultColor;
                        }
                      })(),
                      color: themeColors.primary,
                      fontWeight: "600",
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{ color: "#000", borderBottom: "none", fontSize: "16px" }}
                >
                  {element.name}
                </TableCell>
                <TableCell
                  sx={{ color: "#000", borderBottom: "none", fontSize: "16px" }}
                >
                  {new Date(element.created_at).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </TableCell>
                <TableCell
                  sx={{ color: "#000", borderBottom: "none", fontSize: "16px" }}
                >
                  {element.total_price}
                </TableCell>
                <TableCell
                  sx={{ color: "#000", borderBottom: "none", fontSize: "16px" }}
                >
                  {getModifiedStatus(element.financial_status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};
export default DataTable;
