import React from "react";
import { Box, Typography } from "@mui/material";
import { themeColors } from "../../constant/ThemeColor";
import "../../assets/css/dashboard.css";
import amzonimg from "../../assets/images/amzon-img.png";
import Chart from "../../components/chart";
import { useContext } from "react";
import { LoadingContext } from "../../context/loadingProviderContext";
import { AuthContext } from "../../context/authContext";
import Spinner from "../../components/spinner";
import { useEffect } from "react";
import { getPurchaserAnalytics } from "../../ApisActions/purchaseApisActions";

const Dashboard = () => {



  return (
    <Box component={"div"} pt={2} pb={3} pl={3} pr={"10%"}>
      <Typography variant="h4" color={themeColors.textHeading}>
        Overview
      </Typography>
      <section className="Account-Actives">
        <Typography
          variant="h5"
          color={themeColors.textHeading}
          sx={{ paddingBottom: "0px" }}
        >
          Account Activities
        </Typography>
        <div className="grid-container-dashboard">
          <Box className="grid-item">
            <Typography variant="h6" color={themeColors.gray}>
              Total Purchases
            </Typography>
            <Typography
              variant="h2"
              color={themeColors.textHeading}
              pt={2}
              sx={{ paddingBottom: "10px" }}
            >
              $300
            </Typography>
          </Box>
          <Box className="grid-item">
            <Typography variant="h6" color={themeColors.gray}>
              Total Products Delivered{" "}
            </Typography>
            <Typography
              variant="h2"
              color={themeColors.textHeading}
              pt={2}
              sx={{ paddingBottom: "16px" }}
            >
              40
            </Typography>
          </Box>
          <Box className="grid-item">
            <Typography variant="h6" color={themeColors.gray}>
              Avg Monthly Spending
            </Typography>
            <Typography
              variant="h2"
              color={themeColors.textHeading}
              pt={2}
              sx={{ paddingBottom: "16px" }}
            >
              $4,500
            </Typography>
          </Box>
          <Box className="grid-item">
            <Typography variant="h6" color={themeColors.gray}>
              Avg Delivery Time
            </Typography>
            <Typography
              variant="h2"
              color={themeColors.textHeading}
              pt={2}
              sx={{ paddingBottom: "16px" }}
            >
              2 Days
            </Typography>
          </Box>
        </div>
      </section>

      <section className="account-activities">
        <div className="grid-boxies">
          <Box>
            <Typography
              variant="h5"
              color={themeColors.textHeading}
              sx={{ paddingBottom: "16px" }}
            >
              Spending Activities
            </Typography>
            <Box className="account-active">
              <Typography variant="h6" color={themeColors.gray}>
                Total Spending
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                sx={{ paddingBottom: "20px" }}
              >
                $20,500
              </Typography>
              <Box component={"div"} className="btn-div">
                <button className="btn btn-shop">
                  <Typography
                    variant="h4"
                    sx={{ fontSize: "14px", fontWeight: "500", color: "#000" }}
                  >
                    Shop Products
                  </Typography>
                </button>
              </Box>
            </Box>
          </Box>

          <Box className="">
            <Typography
              variant="h5"
              color={themeColors.textHeading}
              sx={{ paddingBottom: "16px" }}
            >
              Spending Overview
            </Typography>
            <Box className="account-active">
              <Typography
                variant="h6"
                color={themeColors.gray}
                sx={{ paddingBottom: "21px" }}
              >
                Recurring Order Schedule For:
              </Typography>
              <Box component={"div"} className="main-box">
                <Box className="box-product">
                  <div
                    style={{
                      width: "60px",
                      height: "70px",
                      // background: "#000",
                    }}
                  >
                    <img src={amzonimg} alt="" className="empty-box" />
                  </div>
                </Box>
                <Box className="box-text">
                  <Typography
                    variant="h6"
                    color={themeColors.textHeading}
                    sx={{ paddingBottom: "14px", fontWeight: 400 }}
                  >
                    Printer Paper 8.5 x 11
                  </Typography>
                  <Typography variant="h6" color={themeColors.textHeading}>
                    Next Scheduled Order:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "12px", fontWeight: 400 }}
                    color={themeColors.gray}
                  >
                    November 23, 2023
                  </Typography>
                </Box>
              </Box>

              <Box component={"div"} className="btn-div">
                <button className="btn-edit">
                  <Typography
                    variant="h4"
                    sx={{ fontSize: "12px", fontWeight: "400", color: "#000" }}
                  >
                    <a href="#edit">Edit Order</a>
                  </Typography>
                </button>
              </Box>
            </Box>
          </Box>
        </div>
      </section>

      <section className="progress-graph">
        <Typography
          variant="h5"
          color={themeColors.textHeading}
          sx={{ paddingBottom: "16px" }}
        >
          Spending Overview
        </Typography>
        <div className="chart-bg">
          <Chart />
        </div>
      </section>
    </Box>
  );
};

export default Dashboard;
