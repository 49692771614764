import "bootstrap/dist/css/bootstrap.min.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode } from "./theme/theme";
import TopBar from "./global/TopBar";
import SideBar from "./global/SideBar";
import { Route, Routes, useNavigate } from "react-router-dom";
import Settings from "./pages/Settings/Settings";
import Purchases from "./pages/Purchases/Purchases";
import Quotes from "./pages/Quotes/Quotes";
import Members from "./pages/Members/Members";
import Workflows from "./pages/Workflows/Workflows";
import Dashboard from "./pages/dashboard";
import { useEffect } from "react";
import CreateMember from "./pages/Members/createMember";
import { AuthProvider } from "./context/authContext";
import HandleLogingCom from "./components/HandleLogingCom";
import { startPoint } from "./constant/ThemeFonts";
import ViewerWorkflows from "./pages/ViewerWorkflows/ViewerWorkflows";
import "./assets/css/media.css";
import { getSecureCustomerSigFromCookie } from "./constant/ThemeToken";
import { LoadingProvider } from "./context/loadingProviderContext";
import { MembersContext } from "./context/memberListContext";


function App() {
  const [theme] = useMode();

  // useEffect(() => {
  //   const currentPath = window.location.pathname;
  //   console.log("currentPath", currentPath);

  //   if (
  //     (currentPath === `${startPoint}/workflows`,
  //     `${startPoint}/workflows` || currentPath === `${startPoint}/members`)
  //   ) {
  //     navigate(currentPath, { state: { hideTopBar: true } });
  //   }
  // }, [navigate]);

  useEffect(() => {
    // const secureCustomerSig = "258f0da23ed3c9b3154161bc7f2e88c4";
    const secureCustomerSig = getSecureCustomerSigFromCookie();

    const customer = JSON.parse(localStorage.getItem("loggedInCustomerData"));
    const customerId = customer?.customerId;

    if (secureCustomerSig === null && !customerId) {
      window.location.href =
        "https://santamonica-commerce-glass-demo.myshopify.com/account/login";
    }
  }, []);

  return (
 <LoadingProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <SideBar />
            <main className={"content"}>
              <TopBar />
              <Routes>
                <Route
                  exact={true}
                  path={`${startPoint}/`}
                  element={<Dashboard />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/purchases`}
                  element={<Purchases />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/members`}
                  element={<Members />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/createMember`}
                  element={<CreateMember />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/workflows`}
                  element={<Workflows />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/viewerworkflows`}
                  element={<ViewerWorkflows />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/quotes`}
                  element={<Quotes />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/settings`}
                  element={<Settings />}
                />
                <Route
                  exact={true}
                  path={`${startPoint}/login`}
                  element={<HandleLogingCom />}
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </AuthProvider>
    </LoadingProvider> 
  );
}

export default App;
