import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { startPoint } from "../constant/ThemeFonts";

const HandleLogingCom = () => {
  const { user, setUser } = useContext(AuthContext);
  console.log("user NUKOLAS" , user);
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    try {
      const response = await axios.post(
        "https://santamonica.projectanddemoserver.com/users/login",
        { email, password }
      );
      localStorage.setItem("token", response.data.token);
      setUser(response.data.data);
      navigate(`${startPoint}/`);
      setAuthToken(response.data.token);
    } catch (error) {
      console.error("Failed to log in", error);
    }
  };

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  return (
    <div>
      {user && (
        <div style={{ color: "green" }} className="ml-3">
          Welcome, {user?.first_name}!
        </div>
      )}
      {!user && (
        <Button
          className="ml-4 mt-4"
          variant="outlined"
          color="success"
          onClick={() => handleLogin("sujit@ninjatechnolabs.com", "123456789sy")}   //viewer
          // onClick={() => handleLogin("abdul@ninjatechnolabs.com", "123456789as")}  // approval
          // onClick={() =>
          //   handleLogin("janki@ninjatechnolabs.com", "123456789jp")
          // }
           // purchaser
        >
          Log in
        </Button>
      )}
    </div>
  );
};

export default HandleLogingCom;

// HandleLogingCom.js

// const HandleLogingCom = () => {
//   useEffect(() => {
//     const getSecureCustomerSigFromCookie = () => {
//       const cookies = document.cookie.split(";");
//       for (let i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i].trim();
//         if (cookie.startsWith("secure_customer_sig=")) {
//           return cookie.substring("secure_customer_sig=".length);
//         }
//       }
//       return null;
//     };

//     const secureCustomerSig = getSecureCustomerSigFromCookie();
//     console.log("secureCustomerSig", secureCustomerSig);

//     if (secureCustomerSig) {
//     window.location.href = `${startPoint}/`;
//     } else {
//       window.location.href =
//         "https://santamonica-commerce-glass-demo.myshopify.com/account/login";
//     }
//   }, []);

//   return (
//     <>
//       {/* You can add any UI elements or components related to login handling */}
//     </>
//   );
// };

// export default HandleLogingCom;
