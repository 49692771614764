import { Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { themeColors } from "../constant/ThemeColor";

const data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Sep",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Oct",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Nov",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Dec",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Chart = () => {
  return (
    <div>
      <Typography
        variant="h6"
        color={themeColors.gray}
        sx={{ padding: "15px 16px" }}
      >
        Spend History
      </Typography>

      <ResponsiveContainer height={300}>
        <LineChart
          data={data}
          variant="h6"
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            style={{ strokeWidth: "0.5px" }}
            stroke="#D2D2D2"
            horizontal={true}
            vertical={false}
            F
          />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip sx={{ color: themeColors.primary }} />

          <Line
            type="monotone"
            dataKey="pv"
            stroke={themeColors.highLight}
            fill={themeColors.primary}
            strokeWidth={"5px"}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default Chart;

//   return (
//     <div>
//       <Typography
//         variant="h6"
//         color={themeColors.gray}
//         sx={{ padding: "15px 16px" }}
//       >
//         Spend History
//       </Typography>

//       <ResponsiveContainer width="100%" height={300}>
//         <LineChart
//           data={data}
//           syncId="anyId"
//           margin={{
//             top: 5,
//             right: 5,
//             left: 5,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid
//             style={{ strokeWidth: "0.5px" }}
//             stroke="#D2D2D2"
//             horizontal={true}
//             vertical={false}
//           />
//           <XAxis dataKey="name" tick={{ fontSize: 12 }} />
//           <YAxis />
//           <Tooltip
//             contentStyle={{ fontSize: 12 }}
//             labelStyle={{ fontSize: 14 }}
//             itemStyle={{ fontSize: 14 }}
//           />

//           <Line
//             type="monotone"
//             dataKey="pv"
//             stroke={themeColors.highLight}
//             fill={themeColors.primary}
//             strokeWidth={"5px"}
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default Chart;
