import axios from "axios";
import URL from "../../Network/endpoint";

export const getAllUserList = async ({ setLoading = () => {} }) => {
  setLoading(true);
  try {
    const response = await axios.get(`${URL.ALL_USER}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const getTransferList = async (setLoading, id, email) => {
  console.log("userId", id, console);
  setLoading(true);
  try {
    const response = await axios.post(`${URL.TRANSFER_LIST}/${id}`, {
      email: email,
    });
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const getApproveDeclinedList = async ({ setLoading = () => {} }, id) => {
  console.log("getApproveDeclinedList", id);
  setLoading(true);
  try {
    const response = await axios.get(`${URL.APPROVED_DECLINED_LIST}/${id}`);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

// export const approvedRequest = async (id, fname, lname, email, setLoading, customerId) => {
//   try {
//     const response = await axios.post(URL.APPROVED_API, {
//       id: id,
//       approved_declined_by_user_name: fname + " " + lname,
//       approved_declined_by_user_email: email,
//     });
//     console.log("response", response);
//     // getTransferList(email, {setLoading})
//     getTransferList(
//       email,
//       setLoading,
//       customerId,
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

export const approvedRequest = async (
  id,
  fname,
  lname,
  email,
  setLoading = () => {},
  customerId
) => {
  try {
    await axios.post(URL.APPROVED_API, {
      id: id,
      approved_declined_by_user_name: fname + " " + lname,
      approved_declined_by_user_email: email,
    });
    const response = await getTransferList(setLoading, customerId, email);
    await getApproveDeclinedList(setLoading, customerId);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const sendToApprover = async ({ setLoading = () => {} },id, approverId, approverEmail) => {
  setLoading(true);
  try {
    const response = await axios.post(`${URL.SEND_TO_APPROVER}/${id}`, {
      transfer_to_approver_id: approverId,
      transfer_to_approver_email: approverEmail,
    });
    console.log("SEND_TO_APPROVER", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }finally {
    setLoading(false);
  }
};

export const declineRequest = async (id, fname, lname, email) => {
  console.log("fname", fname);
  try {
    const response = await axios.post(URL.DECLINE_API, {
      id: id,
      approved_declined_by_user_name: fname + " " + lname,
      approved_declined_by_user_email: email,
    });
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getTransferCartItems = async (id) => {
  try {
    const response = await axios.get(`${URL.TRANSFERCART_ITEM}/${id}`);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getTransferUserList = async (setLoading, email) => {
  setLoading(true);
  try {
    const response = await axios.post(`${URL.TRANSFERCART_USER_OWN_REQUEST}`, {
      email: email,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};
