import axios from "axios";
import URL from "../../Network/endpoint";

export const getViewerWorkflow = async (setLoading, email) => {
  console.log("email", email);
  setLoading(true);
  try {
    const response = await axios.post(URL.VIEWER_WORKFLOW_API, {
      // email: "komal@ninjatechnolabs.com",
      email: email,
    });
    console.log("responseofviewer", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};
