import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { themeColors } from "../../constant/ThemeColor";
import "../../assets/css/settings.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import URL from "../../Network/endpoint";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
  },
});

const Settings = () => {
  const [data, setData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        settingApi(values);
      },
    });

  const settingApi = async (values) => {
    console.log("values", values);
    delete values.confirmPassword;
    try {
      const response = await axios.post(`${URL.SETTING_API}/1`, values);

      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("data", data);

  return (
    <form onSubmit={handleSubmit}>
      {console.log("values", values)}
      <Box component={"div"} className="settings">
        <Typography
          variant="h4"
          color={themeColors.textHeading}
          sx={{
            padding: "30px 24px",
            color: "#0C151B",
          }}
        >
          Organization account information
        </Typography>
        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              required
              type="email"
              fullWidth
              label="Email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors.email && touched.email && (
              <div className="error-message">{errors.email}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              required
              type={showPassword ? "text" : "password"}
              fullWidth
              label="Password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && touched.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              required
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setshowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.confirmPassword && touched.confirmPassword && (
              <div className="error-message">{errors.confirmPassword}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box component={"div"} sx={{ padding: "10px 24px" }}>
          <button type="submit" className="btn btn-shop">
            <Typography
              variant="h4"
              sx={{ fontSize: "14px", fontWeight: "500", color: "#000" }}
            >
              Submit
            </Typography>
          </button>
        </Box>
      </Box>
    </form>
  );
};

export default Settings;
