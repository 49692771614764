import axios from "axios";
import URL from "../../Network/endpoint";

export const getAllUsersList = async (setLoading, division, role) => {
  // const { setLoading } = useContext(LoadingContext);
  setLoading(true);
  console.log("divisions", division, role);
  try {
    // Extracting the first element from the 'division' array if it is an array
    const divisionName = Array.isArray(division) ? division[0] : division;
    console.log("divisions", division, divisionName);

    const response = await axios.post(URL.MEMBERS_LIST, {
      division_name: divisionName,
      user_role: role,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const addMemberBudget = async (setLoading, amt, id) => {
  setLoading(true);
  console.log("id", id);
  const data = {
    budget: amt,
  };
  console.log("data", data);

  try {
    const response = await axios.post(`${URL.ADD_BUDGET}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const changePurchaseStatus = async (
  setLoading,
  status,
  id
) => {
  setLoading(true);
  const data = {
    purchasing_status: status,
  };
  try {
    const response = await axios.post(
      `${URL.CHANGE_PURCHASE_STATUS}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const changeUserState = async (setLoading , state, id) => {
  setLoading(true);
  const data = {
    user_status: state,
  };
  try {
    const response = await axios.post(`${URL.CHANGE_USER_STATE}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const exportData = async (setLoading , values) => {
  setLoading(true);
  console.log("values", values);

  try {
    const response = await axios.post(URL.EXPORT_DATA, values);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};
