import axios from "axios";
import URL from "../../Network/endpoint";

export const getPurchaserApproverQuoteList = async (
  { setLoading = () => {} },
  email
) => {
  setLoading(true);
  const data = {
    email: email,
  };
  try {
    const response = await axios.post(`${URL.QUOTES_PURCHASER_APPROVER}`, data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};


export const getViewersQuoteList = async (
  { setLoading = () => {} },
  email
) => {
  setLoading(true);
  const data = {
    email: email,
  };
  try {
    const response = await axios.post(`${URL.QUOTES_VIEWERS}`, data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};