import "../../assets/css/purchase.css";
import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { themeColors } from "../../constant/ThemeColor";
import DataTable from "../../components/dataTable";
import PurchaseChartOne from "../../components/purchaseChartOne";
import axios from "axios";
import URL from "../../Network/endpoint";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { LoadingContext } from "../../context/loadingProviderContext";
import Spinner from "../../components/spinner";
import { getPurchaserAnalytics } from "../../ApisActions/purchaseApisActions";

const Purchases = () => {
  const { user } = useContext(AuthContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [orderListData, setOrderListrData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState();

  useEffect(() => {
    if (user) {
      getOrderList();
      fetchPurchasesAnalyticsData();
    }
  }, [user]);

  const getOrderList = async () => {
    try {
      const response = await axios.get(`${URL.ORDER_LIST}`);
      console.log("response", response);
      setOrderListrData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPurchasesAnalyticsData = async () => {
    const { data } = await getPurchaserAnalytics(setLoading, user?.customerId);
    console.log("fetchPurchasesAnalyticsData", data.result);
    setAnalyticsData(data.result);
  };

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        <Spinner />
      </div>
    );
  }

  return (
    <Box component={"div"} pb={3} pl={3} pr={"7%"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{ paddingTop: "16px" }}
      >
        Purchases
      </Typography>
      <section className="Account-Actives-purshases">
        <Typography
          variant="h5"
          color={themeColors.textHeading}
          sx={{ paddingBottom: "16px" }}
        >
          Purchase Overview
        </Typography>
        <DataTable orderListData={orderListData} />
        <Box>
          <Typography
            variant="h5"
            color={themeColors.textHeading}
            sx={{ paddingTop: "24px" }}
          >
            Spending Activities
          </Typography>
          <div className="grid-container">
            <Box className="grid-item">
              <Typography variant="h6" color={themeColors.gray}>
                Total Individual Spending
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                className="individualspendingheading"
              >
                $2,500
              </Typography>
            </Box>
            <Box className="grid-item">
              <Typography variant="h6" color={themeColors.gray}>
                Total Division Spending
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                sx={{ paddingBottom: "16px" }}
              >
                $20,500{" "}
              </Typography>
            </Box>
            <Box className="grid-item">
              <Typography variant="h6" color={themeColors.gray}>
                Total Monthly Spending
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                sx={{ paddingBottom: "16px" }}
              >
                $3,100
              </Typography>
            </Box>
          </div>
        </Box>
      </section>
      <section className="charts-config">
        <div className="row">
          <div className="col-12">
            <Typography
              variant="h5"
              color={themeColors.textHeading}
              sx={{ paddingBottom: "16px" }}
            >
              Category Spending Overview
            </Typography>
            <div className="chat-bg">
              <Typography
                variant="h6"
                color={themeColors.gray}
                sx={{ paddingLeft: "17px", paddingBottom: "15px" }}
              >
                Spend History
              </Typography>
              <PurchaseChartOne analyticsData={analyticsData} />
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
};
export default Purchases;
