import { Box, Typography } from "@mui/material";
import RequestforOrderApprovalTable from "../../components/RequestforOrderApprovalTable";
import { themeColors } from "../../constant/ThemeColor";
import PreviousOrderRequestsTable from "../../components/PreviousOrderRequestsTable";
import { useState, useEffect } from "react";
import {
  getAllUserList,
  getApproveDeclinedList,
  getTransferList,
  getTransferUserList,
} from "../../ApisActions/workflowApisActions";
import Spinner from "../../components/spinner";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { LoadingContext } from "../../context/loadingProviderContext";
import UserOwnRequests from "../../components/UserOwnRequests";

const Workflows = () => {
  const [trasferListData, setTransferListData] = useState(null);
  const [approveDeclineData, setApproveDeclineData] = useState(null);
  const [allUserList, setAllUserList] = useState(null);
  const [userRequestData, setUserRequestData] = useState(null);
  const { loading, setLoading } = useContext(LoadingContext);

  const { user } = useContext(AuthContext);
  console.log("user Instance", user?.customerId);

  // const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const response = await getTransferList(setLoading, user?.customerId, user?.email);
    console.log("response fetchData" , response);
    setTransferListData(response);
  };
  
  const userRequest = async () => {
    const response = await getTransferUserList(setLoading, user?.email);
    setUserRequestData(response);
    console.log("response.data", response);
  };
  const fetchApprovedDeclinedData = async () => {
    const response = await getApproveDeclinedList(
      { setLoading },
      user?.customerId
    );
    setApproveDeclineData(response);
  };

  const fetchAllUserList = async () => {
    const { data } = await getAllUserList(setLoading);
    setAllUserList(data);
  };

  useEffect(() => {
    if (user) {
      fetchApprovedDeclinedData();
      fetchData();
      userRequest();
      fetchAllUserList();
    }
  }, [user]);

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        <Spinner />
      </div>
    );
  }

  return (
    <Box component={"div"} pb={3} pl={3} pr={"7%"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingTop: "41px",
          paddingBottom: "16px",
        }}
      >
        Workflows
      </Typography>

      <RequestforOrderApprovalTable
        trasferListData={trasferListData}
        fetchData={fetchData}
        fetchApprovedDeclinedData={fetchApprovedDeclinedData}
        allUserList={allUserList}
      />

      <PreviousOrderRequestsTable approveDeclineData={approveDeclineData} allUserList={allUserList} />

      {user?.user_role == "Purchaser" && (
        <UserOwnRequests userRequestData={userRequestData} />
      )}
    </Box>
  );
};
export default Workflows;
