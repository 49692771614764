import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import "../assets/css/purchase.css";
import URL from "../Network/endpoint";
import axios from "axios";
import Spinner from "./spinner";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { LoadingContext } from "../context/loadingProviderContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6F7C84",
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    background: "#fff",
    color: "#0C151B",
    borderBottom: "1px solid #EFEFEF",
    padding: "30px",
    textAlign: "-webkit-center",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    color: "#000",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiIconButton-root": {
    marginLeft: theme.spacing(1),
  },
}));

const PreviousOrderRequestsTable = ({ approveDeclineData, allUserList }) => {
  const { loading, setLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);

  const handleCheckout = async (id) => {
    setLoading(true);
    try {
      const response = await axios.post(URL.CREATESHOPIFYCHECKOUT_API, {
        cart_id: id,
        shopName: "santamonica-commerce-glass-demo.myshopify.com",
      });
      var webCheckoutUrl = response.data.data.checkout.web_url;
      window.location.href = webCheckoutUrl;
      console.log(webCheckoutUrl);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        <Spinner />
      </div>
    );
  }

  // if (!approveDeclineData?.data || approveDeclineData?.data.length === 0) {
  //   return (
  //     <Typography
  //       variant="body2"
  //       color={themeColors.textHeading}
  //       sx={{ padding: 2 }}
  //     >
  //       No data available.
  //     </Typography>
  //   );
  // }

  return (
    <Box component={"div"}>
      <Typography
        variant="h5"
        color={themeColors.textHeading}
        className="previousorderrequestsheading"
      >
        Transfer Cart Status
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ background: "#6F7C84", textAlign: "center" }}>
            <TableRow>
              <StyledTableCell>Request</StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">
                  {user?.user_role === "Purchaser" ? "Viewer" : "Purchaser"}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Workflow Name</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Cart</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Price</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Notes</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Action</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approveDeclineData?.data?.length > 0 ? (
              approveDeclineData?.data?.map((element, index) => {
                var cartPreviewParams =
                  "transferCartId=" +
                  element.id +
                  "&transferCartDivision=" +
                  element.division;
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {element?.id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box component={"span"} sx={{ display: "block" }}>
                        {element?.transfer_by_user_name}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{element.workflow}</StyledTableCell>
                    <StyledTableCell align="center">
                      {element.status == "Accepted" ? (
                        user?.user_role == "Approver" ? (
                          <>
                            <Box component={"div"} className="btn-link-div">
                              <Box component={"div"} className="btn-link-div">
                                <button
                                  onClick={() => {
                                    let redirectionUrl = `https://santamonica-commerce-glass-demo.myshopify.com/pages/workflow-preview-cart?token=${btoa(
                                      cartPreviewParams
                                    )}`;
                                    window.location.href = redirectionUrl;
                                  }}
                                  className="btn workflow-Approval-preview"
                                >
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Preview
                                  </Typography>
                                </button>
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box component={"div"} className="btn-link-div">
                              <Box component={"div"} className="btn-link-div">
                                <button
                                  onClick={() => handleCheckout(element.id)}
                                  className="btn workflow-Approval-checkout"
                                >
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Checkout
                                  </Typography>
                                </button>
                              </Box>
                            </Box>
                          </>
                        )
                      ) : (
                        <Box component={"div"} className="btn-link-div">
                          <Box component={"div"} className="btn-link-div">
                            <button
                              onClick={() => {
                                let redirectionUrl = `https://santamonica-commerce-glass-demo.myshopify.com/pages/workflow-preview-cart?token=${btoa(
                                  cartPreviewParams
                                )}`;
                                window.location.href = redirectionUrl;
                              }}
                              className="btn workflow-Approval-preview"
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  textTransform: "capitalize",
                                }}
                              >
                                Preview
                              </Typography>
                            </button>
                          </Box>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {parseFloat(element.total / 100).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {element.notes}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box component={"div"} className="btn-link-div">
                        <Box component={"div"} className="btn-link-div">
                          {element.status === "Rejected" ? (
                            <button className="btn workflow-Approval-pending">
                              <Typography
                                variant="h4"
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  textTransform: "capitalize",
                                }}
                              >
                                {element?.status}{" "}
                                {user?.user_role !== "Approver" &&
                                  "| " + user?.first_name}
                              </Typography>
                            </button>
                          ) : (
                            <button className="btn workflow-Approval">
                              <Typography
                                variant="h4"
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  textTransform: "capitalize",
                                }}
                              >
                                {element?.status}
                                {element.transfer_to_approver_id == null
                                  ? " | " + element.transfer_to_user_name 
                                  : user.first_name !==
                                    allUserList?.find(
                                      (obj) =>
                                        obj.customerId ==
                                        element.transfer_to_approver_id
                                    )?.first_name
                                  ? allUserList?.find(
                                      (obj) =>
                                        obj.customerId ==
                                        element.transfer_to_approver_id
                                    )?.first_name
                                  : ""}
                              </Typography>
                            </button>
                          )}
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={7} align="center">
                  No Records Found...
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default PreviousOrderRequestsTable;
