import { Box, Typography } from "@mui/material";
import { themeColors } from "../../constant/ThemeColor";
import { useState, useEffect, useContext } from "react";
import PreviousOrderRequestsTableViewer from "../../components/PreviousOrderRequestsTableViewer";
import { getViewerWorkflow } from "../../ApisActions/viewerWorkflowApisActions";
import { AuthContext } from "../../context/authContext";
import Spinner from "../../components/spinner";
import { LoadingContext } from "../../context/loadingProviderContext";



const ViewerWorkflows = () => {
  const { user } = useContext(AuthContext);
  const [viewerWorkflow, setViewerWorkflow] = useState(null);
  const { loading, setLoading } = useContext(LoadingContext);

  console.log("user", user?.email);

  const fetchData = async () => {
    const response = await getViewerWorkflow(setLoading, user?.email);
    console.log("response1", response?.data);
    setViewerWorkflow(response);
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        <Spinner />
      </div>
    );
  }

  return (
    <Box component={"div"} pb={3} pl={3} pr={"30px"} width={"100%"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingTop: "41px",
          paddingBottom: "16px",
        }}
      >
        Viewer Workflows
      </Typography>

      <PreviousOrderRequestsTableViewer viewerWorkflow={viewerWorkflow} />
    </Box>
  );
};
export default ViewerWorkflows;
