export const themeFonts = {
  extraBold: "nesxbd",
  semiBoldItalic: "nesSemiBd",
  extraBoldItalic: "nesxbditalic",
  mediumItalic: "nesmdi",
  semiBold: "nessemiBd",
  regular: "nesregular",
  medium: "nesMed",
  lightItalic: "neslightitalic",
  light: "neslight",
  boldItalic: "nesBoldItalic",
  bold: "nesBold",
  italic: "nesItalicNormal",
};

export const themeSettings = {
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 12,
  },
  h1: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 40,
  },
  h2: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 32,
  },
  h3: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 24,
  },
  h4: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 20,
  },
  h5: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 16,
  },
  h6: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 14,
  },
};

// export const startPoint = "https://santamonica-commerce-glass-demo.myshopify.com/pages/testddd"
export const startPoint = "/pages/dashboard";
