import { useEffect, useState } from 'react';

const getDeviceConfig = (width) => {
  if(width < 576) {
    return 'xs'; // phones
  } else if(width < 768) {
    return 'sm'; // tablets
  } else if(width < 992) {
    return 'md'; // small laptops
  } else if(width < 1200) {
    return 'lg'; // desktops, large laptops
  } else {
    return 'xl'; // large desktops
  }
}

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceConfig(window.innerWidth));
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { deviceType };
}

export default useDeviceType;