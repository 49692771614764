import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import "../assets/css/purchase.css";
import {
  approvedRequest,
  declineRequest,
  getTransferCartItems,
} from "../ApisActions/workflowApisActions";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import Spinner from "./spinner";
import { LoadingContext } from "../context/loadingProviderContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6F7C84",
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    background: "#fff",
    color: "#0C151B",
    borderBottom: "1px solid #EFEFEF",
    padding: "20px",
    textAlign: "-webkit-center",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    color: "#000",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiIconButton-root": {
    marginLeft: theme.spacing(1),
  },
}));

const UserOwnRequests = ({
  userRequestData,
  fetchData,
  fetchApprovedDeclinedData,
  fetchTransferCartData,
}) => {
  console.log("userRequestData", userRequestData);
  const { user } = useContext(AuthContext);
  console.log("user NUKOLAS", user);

  return (
    <Box component={"div"}>
      <Typography
        variant="h5"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingTop: "41px",
          paddingBottom: "16px",
        }}
      >
        Your Cart Requests
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 320 }} aria-label="customized table">
          <TableHead sx={{ background: "#6F7C84", textAlign: "center" }}>
            <TableRow>
              <StyledTableCell>Request</StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">
                  {user?.user_role === "Purchaser" ? "Viewer" : "Purchaser"}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Workflow Name</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Cart</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Price</Typography>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Typography variant="">Action</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRequestData?.data?.length > 0 ? (
              userRequestData?.data?.map((element, index) => {
                var cartPreviewParams =
                  "transferCartId=" +
                  element.id +
                  "&transferCartDivision=" +
                  element.division;
                return (
                  <StyledTableRow key={element.id}>
                    <StyledTableCell component="th" scope="row">
                      {element.id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box component={"span"} sx={{ display: "block" }}>
                        {element.transfer_by_user_name}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{element.workflow}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Box component={"div"} className="btn-link-div">
                        <Box component={"div"} className="btn-link-div">
                          <button
                            onClick={() => {
                              let redirectionUrl = `https://santamonica-commerce-glass-demo.myshopify.com/pages/workflow-preview-cart?token=${btoa(
                                cartPreviewParams
                              )}`;
                              window.location.href = redirectionUrl;
                            }}
                            className="btn workflow-Approval-preview"
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: 500,
                                textTransform: "capitalize",
                              }}
                            >
                              Preview
                            </Typography>
                          </button>
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      ${parseFloat(element.total / 100).toFixed(2)}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {element.transfer_by_user_email == user.email ||
                      (element.transfer_to_approver_id &&
                        user.user_role == "Purchaser") ? (
                        <Box component={"div"} className="btn-link-div">
                          <Box component={"div"} className="btn-link-div">
                            <button
                              className={`btn ${
                                element.status == "Pending"
                                  ? "viewersWorkflow-Approval-pending"
                                  : element.status == "Rejected"
                                  ? "viewersWorkflow-Approval-declined"
                                  : "viewersWorkflow-Approval-approved"
                              } `}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  textTransform: "capitalize",
                                }}
                              >
                                {element?.status} | {user?.first_name}
                              </Typography>
                            </button>
                          </Box>
                        </Box>
                      ) : user?.order_budget >
                          parseFloat(element.total / 100).toFixed(2) ||
                        user.user_role !== "Purchaser" ? (
                        <>
                          <Box component={"div"} className="btn-link-div">
                            <Box component={"div"} className="btn-div">
                              <button
                                className="btn btn-shop-for-workflow"
                                onClick={async () => {
                                  await approvedRequest(
                                    element.id,
                                    user.first_name,
                                    user.last_name,
                                    user.email
                                  );
                                  fetchData();
                                  fetchApprovedDeclinedData();
                                }}
                              >
                                <Typography
                                  variant="h4"
                                  sx={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Accept
                                </Typography>
                              </button>
                            </Box>
                            <Box component={"div"} className="btn-div">
                              <button className="btn-deny">
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#000",
                                  }}
                                >
                                  <button
                                    className="deny-btn"
                                    onClick={async () => {
                                      await declineRequest(
                                        element.id,
                                        user.first_name,
                                        user.last_name,
                                        user.email
                                      );
                                      fetchData();
                                      fetchApprovedDeclinedData();
                                    }}
                                  >
                                    Deny
                                  </button>
                                </Typography>
                              </button>
                            </Box>
                          </Box>{" "}
                        </>
                      ) : (
                        <Chip
                          label="Send to Approver"
                          sx={{
                            cursor: "pointer",
                            backgroundColor: themeColors.highLight,
                            color: themeColors.primary,
                            border: "1px solid #d7f62f",
                          }}
                          variant="outlined"
                          onClick={() => {
                            fetchTransferCartData(element.id);
                          }}
                        />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={6} align="center">
                  No Records Found...
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default UserOwnRequests;
