export const getSecureCustomerSigFromCookie = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("secure_customer_sig=")) {
      return cookie.substring("secure_customer_sig=".length);
    }
  }
  return null;
};
