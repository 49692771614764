import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import "../assets/css/purchase.css";
import { useState, useContext } from "react";
import Spinner from "./spinner";
import { LoadingContext } from "../context/loadingProviderContext";
import { AuthContext } from "../context/authContext";
import { getAllUserList } from "../ApisActions/workflowApisActions";
import { useEffect } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6F7C84",
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    background: "#fff",
    color: "#0C151B",
    borderBottom: "1px solid #EFEFEF",
    padding: "30px",
    textAlign: "-webkit-center",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    color: "#000",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiIconButton-root": {
    marginLeft: theme.spacing(1),
  },
}));

const PreviousOrderRequestsTableViewer = ({ viewerWorkflow }) => {
  const { loading, setLoading } = useContext(LoadingContext);
  console.log("viewerWorkflow", viewerWorkflow);
  const { user } = useContext(AuthContext);
  const [allUserList, setAllUserList] = useState();

  useEffect(() => {
    fetchAllUserList();
  }, []);

  const fetchAllUserList = async () => {
    const { data } = await getAllUserList(setLoading);
    setAllUserList(data);
  };

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        <Spinner />
      </div>
    );
  }

  return (
    <Box component={"div"}>
      <Typography
        variant="h5"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingBottom: "16px",
        }}
      >
        Your Transfer Cart Requests
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ background: "#6F7C84", textAlign: "center" }}>
            <TableRow>
              <StyledTableCell>Request</StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Purchaser</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Cart</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Price</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Notes</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Status</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewerWorkflow?.data?.map((element, index) => {
              var cartPreviewParams =
                "transferCartId=" +
                element.id +
                "&transferCartDivision=" +
                element.division;
              return (
                <StyledTableRow key={index}>
                  {console.log("element", element)}
                  <StyledTableCell component="th" scope="row">
                    {element?.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box component={"span"} sx={{ display: "block" }}>
                      {element?.transfer_to_user_name}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {/* <Link
                      to={`www.commerce.glass/checkout91011`}
                      target="_blank"
                    > */}
                    {/* <Chip
                      className="preview-chip"
                      label="Preview"
                      sx={{
                        backgroundColor: themeColors.primary,
                        color: themeColors.white,
                        border: "1px solid #000",
                        width: "100px",
                      }}
                      variant="outlined"
                      onClick={() => {
                        let redirectionUrl = `https://santamonica-commerce-glass-demo.myshopify.com/pages/workflow-preview-cart?token=${btoa(
                          cartPreviewParams
                        )}`;
                        window.location.href = redirectionUrl;
                      }}
                    /> */}
                    <Box component={"div"} className="btn-link-div">
                      <Box component={"div"} className="btn-link-div">
                        <button
                          onClick={() => {
                            let redirectionUrl = `https://santamonica-commerce-glass-demo.myshopify.com/pages/workflow-preview-cart?token=${btoa(
                              cartPreviewParams
                            )}`;
                            window.location.href = redirectionUrl;
                          }}
                          className="btn viewersWorkflow-Approval-preview"
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              textTransform: "capitalize",
                            }}
                          >
                            Preview
                          </Typography>
                        </button>
                      </Box>
                    </Box>
                    {/* </Link> */}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ${parseFloat(element.total / 100).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {element.notes}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box component={"div"} className="btn-link-div">
                      <Box component={"div"} className="btn-link-div">
                        <button
                          className={`btn ${
                            element.status == "pending"
                              ? "viewersWorkflow-Approval-pending"
                              : element.status == "Rejected"
                              ? "viewersWorkflow-Approval-declined"
                              : "viewersWorkflow-Approval-approved"
                          } `}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              textTransform: "capitalize",
                            }}
                          >
                            {element?.status} |{" "}
                            {element.transfer_to_approver_id == null
                              ? element.transfer_to_user_name
                              : allUserList?.find(
                                  (obj) =>
                                    obj.customerId ==
                                    element.transfer_to_approver_id
                                )?.first_name}
                          </Typography>
                        </button>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default PreviousOrderRequestsTableViewer;
