import axios from "axios";
import URL from "../../Network/endpoint";

export const getPurchaserAnalytics = async (
  { setLoading = () => {} },
  customerID
) => {
  setLoading(true);
  
  try {
    const response = await axios.get(`${URL.PURCHASER_ANALYTICS}/${customerID}`);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};


