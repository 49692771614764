import { Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { themeColors } from "../constant/ThemeColor";
import { useState } from "react";
import { useEffect } from "react";

const data = [
  {
    name: "Furniture",
    uv: 4000,
    pv: 4.0,
    amt: 2400,
  },
  {
    name: "Hardware",
    uv: 3000,
    pv: 3.0,
    amt: 2210,
  },
  {
    name: "Food Items",
    uv: 2000,
    pv: 3.5,
    amt: 2290,
  },
  {
    name: "Electronics",
    uv: 2780,
    pv: 4.9,
    amt: 2000,
  },
  {
    name: "Apparal & Accesories",
    uv: 2000,
    pv: 3.5,
    amt: 2290,
  },
  {
    name: "Media",
    uv: 2000,
    pv: 3.9,
    amt: 2290,
  },
  {
    name: "Veteran-Owned",
    uv: 2000,
    pv: 3.4,
    amt: 2290,
  },
];

const getIntroOfPage = (label) => {
  if (label === "Page A") {
    return "Page A is about men's clothing";
  }
  if (label === "Page B") {
    return "Page B is about women's dress";
  }
  if (label === "Page C") {
    return "Page C is about women's bag";
  }
  if (label === "Page D") {
    return "Page D is about household goods";
  }
  if (label === "Page E") {
    return "Page E is about food";
  }
  if (label === "Page F") {
    return "Page F is about baby food";
  }
  return "";
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <Box
          component={"label"}
          color={themeColors.primary}
          className="label"
        >{`${label} : ${payload[0].value}`}</Box>
        <Box component={"label"} color={themeColors.primary} className="intro">
          {getIntroOfPage(label)}
        </Box>
        {/* <Box component={"p"} color={themeColors.primary} className="desc">
          Anything you want can be displayed here.
        </Box> */}
      </div>
    );
  }

  return null;
};

const PurchaseChartOne = ({ analyticsData }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const transformedData = analyticsData?.map((item, index) => ({
      name: item.collection,
      pv: item.total_spending,
      index: index + 1,
    }));

    setChartData(transformedData);
  }, [analyticsData]);

  const formatYAxis = (tickItem) => {
    return tickItem;
  };

  console.log("analyticsData", analyticsData);
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          style={{ strokeWidth: "0.5px" }}
          stroke="#D2D2D2"
          horizontal={true}
          vertical={false}
        />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          angle={-55}
          textAnchor="end"
        />
        <YAxis
          domain={[1.0, 10.0]}
          axisLine={false}
          tickLine={false}
          tickFormatter={formatYAxis}
        />
        {/* <Tooltip content={<CustomTooltip />} /> */}

        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const dataPoint = payload[0].payload;
              return (
                <div className="custom-tooltip">
                  <Box
                    component={"label"}
                    color={themeColors.primary}
                    className="label"
                  >{`Collection: ${dataPoint.name}, Spending: ${payload[0].value}`}</Box>
                </div>
              );
            }
            return null;
          }}
        />
        <Bar dataKey="pv" barSize={60} fill="#D7F62F" />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default PurchaseChartOne;
