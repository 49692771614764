import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import URL from "../Network/endpoint"

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const customerCred = JSON.parse(localStorage.getItem("loggedInCustomerData")) || [];

  useEffect(() => {
    getUserInstance(customerCred?.customerId);
  }, []);

  const getUserInstance = async (id) => {
    try {
      const { data } = await axios.post(URL.USER_PROFILE, {
        customerId: `${id}`,
      });
      console.log("data", data);
      setUser(data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
